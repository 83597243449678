<template>
  <div class="device-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form">
            <el-select
              v-model="listfilter.dangerid"
              filterable
              remote
              reserve-keyword
              default-first-option
              clearable
              placeholder="请选择监测点"
              size="medium"
              :remote-method="getDangerList"
              :loading="getDangerLoading"
              @change="onSearch"
            >
              <el-option
                v-for="item in dangerSelect"
                :key="item.Id"
                :label="item.DangerName"
                :value="item.Id"
              >
              </el-option>
            </el-select>

            <el-date-picker
              v-model="filterDaterange"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="medium"
              style="width: 240px; margin-left: 10px"
              value-format="yyyy-MM-dd"
              @change="dateChange"
            >
            </el-date-picker>
            <el-button
                style="margin-left: 10px"
                type="primary"
                size="medium"
                icon="el-icon-search"
                @click="onSearch"
                >查询</el-button
            >
          </el-form>
        </div>
        <div class="action-right">
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            size="medium"
            @click="onAdd"
            >新增</el-button
          >
        </div>
      </div>
    </div>

    <el-table
      class="list-table"
      ref="multipleTable"
      :data="listData"
      tooltip-effect="dark"
      style="width: 100%"
      :stripe="true"
      :border="false"
      v-loading="tableLoading"
    >
      <el-table-column label="监测点位" prop="MonitorName"></el-table-column>
      <el-table-column label="运维人员" prop="UserName"></el-table-column>
      <el-table-column label="联系电话" prop="Mobile"></el-table-column>
      <el-table-column label="巡查内容" prop="Content"></el-table-column>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <el-button
            type="info"
            icon="el-icon-camera"
            size="mini"
            @click="onShowDetailImages(scope.row)"
            >查看图片</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="CreateTime">
        <template slot-scope="scope">
          {{ scope.row.CreateTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-button
            type="success"
            icon="el-icon-view"
            size="mini"
            @click="onShowDetail(scope.row)"
            >详情</el-button
          >
          <el-button
            type="success"
            icon="el-icon-edit"
            size="mini"
            @click="onEdit(scope.row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      layout="prev, pager, next"
      prev-text="上一页"
      next-text="下一页"
      class="list-pagination"
      :page-size="page.pagesize"
      hide-on-single-page
      :total="page.total"
      :current-page.sync="page.pageindex"
      @current-change="onPageChange"
    >
    </el-pagination>

    <el-dialog
      :title="formtype == 'add' ? '添加巡查' : '修改巡查'"
      :visible.sync="dialogFormVisible"
      width="750px"
      custom-class="geology-dialog"
      :modal-append-to-body="true"
      :destroy-on-close="true"
      :before-close="onFormClose"
    >
      <el-form
        ref="form"
        class="geology-form"
        :rules="formRules"
        :model="form"
        label-width="120px"
        style="padding-top: 20px"
      >
        <el-row>
          <el-col :span="10" :offset="1">
            <el-form-item label="监测点" prop="DangerId">
              <el-select
                v-model="form.DangerId"
                filterable
                remote
                reserve-keyword
                default-first-option
                placeholder="请选择监测点"
                size="medium"
                :remote-method="getDangerList"
                :loading="getDangerLoading"
                @change="onDangerChange"
              >
                <el-option
                  v-for="item in dangerSelect"
                  :key="item.Id"
                  :label="item.DangerName"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="监测点位" prop="MonitorId">
              <el-select
                v-model="form.MonitorId"
                filterable
                remote
                reserve-keyword
                default-first-option
                placeholder="请选择监测点位"
                size="medium"
                :remote-method="getMonitorList"
                :loading="getMonitorLoading"
                @change="onMonitorChange"
              >
                <el-option
                  v-for="item in monitorSelect"
                  :key="item.Id"
                  :label="item.MonitorName"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="运维单位" prop="MaintainUnitId">
              <el-input
                readonly=""
                v-model="form.UnitName"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="运维人员" prop="MaintainUnitUserId">
              <el-input
                readonly
                v-model="form.UserName"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21" :offset="1">
            <el-form-item label="内容" prop="Content">
              <el-input
                type="textarea"
                v-model="form.Content"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21" :offset="1">
            <el-form-item label="图片" prop="ImageList">
              <multi-file
                ref="inspectMulitFile"
                text="选择图片"
                :auto-upload="false"
                :show-file-list="true"
                :file-list="formImageList"
                list-type="picture-card"
                @success="uploadSuccess"
                @complete="uploadComplete"
              ></multi-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23" :offset="1">
            <el-form-item>
              <el-button type="primary" size="medium" @click="onSubmit"
                >立即保存</el-button
              >
              <el-button
                type="primary"
                plain
                size="medium"
                style="margin-left: 10px"
                @click="onFormClose"
                >取消</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog
      title="巡查图片"
      :visible.sync="dialogDetailImagesVisible"
      width="60%"
      custom-class="geology-dialog"
      :modal-append-to-body="true"
    >
      <div style="padding:20px 0;">
        <el-image
          style="width: 80px; height: 80px; margin-right: 20px"
          v-for="(item, index) in activeInspectDetail.ImageList
            ? activeInspectDetail.ImageList.split(',')
            : []"
          :key="index"
          :src="item"
          :preview-src-list="[item]"
        >
        </el-image>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          style="margin-left: 10px"
          plain
          type="primary"
          @click="dialogDetailImagesVisible = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="巡查详情"
      :visible.sync="dialogDetailVisible"
      width="60%"
      custom-class="geology-dialog"
      :modal-append-to-body="true"
    >
      <el-form
        class="geology-form"
        label-width="120px"
        style="padding-top: 20px"
      >
        <el-row>
          <el-col :span="10" :offset="1">
            <el-form-item label="监测点:">
              {{ activeInspectDetail.MonitorName }}
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="监测点位:">
              {{ activeInspectDetail.MonitorName }}
            </el-form-item>
          </el-col>
          <el-col :span="21" :offset="1">
            <el-form-item label="运维单位:">
              {{ activeInspectDetail.UnitName }}
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="运维人员:">
              {{ activeInspectDetail.UserName }}
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="联系电话:">
              {{ activeInspectDetail.Mobile }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21" :offset="1">
            <el-form-item label="内容:">
              {{ activeInspectDetail.Content }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21" :offset="1">
            <el-form-item label="图片:">
              <el-image
                style="width: 80px; height: 80px; margin-right: 10px"
                v-for="(item, index) in activeInspectDetail.ImageList
                  ? activeInspectDetail.ImageList.split(',')
                  : []"
                :key="index"
                :src="item"
                :preview-src-list="[item]"
              >
              </el-image>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          style="margin-left: 10px"
          plain
          type="primary"
          @click="dialogDetailVisible = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getDangerList, getMonitorList } from "@/api/base";
import {
  getDeviceInspectList,
  addDeviceInspect,
  editDeviceInspect,
} from "@/api/device";
import { MultiFile } from "@/components/upload";
export default {
  data() {
    return {
      listData: [],
      tableLoading: false,

      filterDaterange: "",
      getDangerLoading: false,
      dangerSelect: [],
      getMonitorLoading: false,
      monitorSelect: [],

      listfilter: {
        enabled: "",
        realname: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },

      dialogFormVisible: false,
      roleSelect: [],
      formtype: "add",
      formImageList: [],
      form: {
        DangerId: "",
        MonitorId: "",
      },
      formRules: {
        DangerId: [
          { required: true, trigger: "blur", message: "请选择监测点" },
        ],
        MonitorId: [
          { required: true, trigger: "blur", message: "请选择监测点位" },
        ],
      },

      dialogDetailVisible: false,
      activeInspectDetail: "",

      dialogDetailImagesVisible: false,
    };
  },
  components: {
    MultiFile,
  },
  methods: {
    getDangerList(value) {
      this.getDangerLoading = true;
      getDangerList(1, 10, {
        fname: value,
      }).then((resdata) => {
        this.getDangerLoading = false;
        if (resdata.code == 0) {
          this.dangerSelect = resdata.data.data;
        }
      });
    },
    getMonitorList(value) {
      this.getMonitorLoading = true;
      getMonitorList(1, 10, {
        fname: value,
        dangerid: this.form.dangerid,
      }).then((resdata) => {
        this.getMonitorLoading = false;
        if (resdata.code == 0) {
          this.monitorSelect = resdata.data.data;
        }
      });
    },
    dateChange(value) {
      if (value) {
        var newdate = [];
        for (let index = 0; index < this.filterDaterange.length; index++) {
          const element = this.filterDaterange[index];
          newdate.push(element);
        }
        this.listfilter.fstarttime = newdate[0];
        this.listfilter.fendtime = newdate[1];
        this.getListData();
      } else {
        this.listfilter.fstarttime = "";
        this.listfilter.fendtime = "";
        this.getListData();
      }
    },
    onSearch() {
      this.page.pageindex = 1;
      this.getListData();
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      getDeviceInspectList(
        this.page.pageindex,
        this.page.pagesize,
        this.listfilter
      ).then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data.data;
          this.page.total = resdata.data.count * 1;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onAdd() {
      this.form = {
        dangerid: "",
        MonitorId: "",
        Content: "",
      };
      this.formtype = "add";
      this.dialogFormVisible = true;
    },
    onEdit(item) {
      this.form = item;
      this.formtype = "edit";
      this.formImageList = [];
      item.ImageList.split(",").forEach((element) => {
        this.formImageList.push({
          url: element,
        });
      });
      this.dialogFormVisible = true;
      this.getMonitorList();
    },
    onDangerChange() {
      this.getMonitorList();
    },
    onMonitorChange(value) {
      this.monitorSelect.every((item) => {
        if (item.Id == value) {
          this.form.MaintainUnitId = item.UnitId;
          this.form.UnitName = item.UnitName;
          this.form.MaintainUnitUserId = item.UnitUserId;
          this.form.UserName = item.Uname;
          return false;
        } else {
          return true;
        }
      });
    },
    uploadSuccess(file) {
      console.log(file);
    },
    uploadComplete(files) {
      var filesurl = [];
      if (files.length > 0) {
        files.forEach((item) => {
          filesurl.push(item.path);
        });
      }
      this.form.ImageList = filesurl.join(",");
      if (this.formtype != "edit") {
        addDeviceInspect(this.form)
          .then((res) => {
            if (res.code == 0) {
              this.$message.success("保存成功!");
              this.onFormClose();
            } else {
              this.$message.error("保存失败!" + res.msg);
            }
          })
          .catch((errmsg) => {
            this.$message.error("保存失败!" + errmsg);
          });
      } else {
        editDeviceInspect(this.form)
          .then((res) => {
            if (res.code == 0) {
              this.$message.success("保存成功!");
              this.onFormClose();
            } else {
              this.$message.error("保存失败!" + res.msg);
            }
          })
          .catch((errmsg) => {
            this.$message.error("保存失败!" + errmsg);
          });
      }
    },
    onFormClose() {
      this.getListData();
      this.dialogFormVisible = false;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$refs.inspectMulitFile.submit();
        } else {
          return false;
        }
      });
    },
    onShowDetail(item) {
      this.activeInspectDetail = item;
      this.dialogDetailVisible = true;
    },
    onShowDetailImages(item) {
      //scope.row.ImageList.split(',')
      this.activeInspectDetail = item;
      this.dialogDetailImagesVisible = true;
    },
  },
  created() {
    this.getDangerList();
    this.getListData();
  },
};
</script>